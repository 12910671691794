<template>
  <div class="set-password">
    <p class="title">设置密码</p>
    <div class="box">
      <div class="box-input flex flex-between align-center">
        <input
          v-model="password"
          :type="oneShow ? 'text' : 'password'"
          placeholder="输入密码"
          maxlength="16"
        />
        <img
          v-show="!oneShow"
          src="@/assets/img/loginRegister/no-eye.png"
          alt=""
          @click="eyeFun('one', true)"
        />
        <img
          v-show="oneShow"
          src="@/assets/img/loginRegister/eye.png"
          alt=""
          @click="eyeFun('one', false)"
        />
      </div>
    </div>
    <div class="box">
      <div class="box-input flex flex-between align-center">
        <input
          v-model="againPassword"
          :type="twoShow ? 'text' : 'password'"
          placeholder="再次输入密码"
          maxlength="16"
        />
        <img
          v-show="!twoShow"
          src="@/assets/img/loginRegister/no-eye.png"
          alt=""
          @click="eyeFun('two', true)"
        />
        <img
          v-show="twoShow"
          src="@/assets/img/loginRegister/eye.png"
          alt=""
          @click="eyeFun('two', false)"
        />
      </div>
    </div>
    <p class="tip">密码为8-12位，需要有英文大小写</p>
    <p
      :class="['register', { 'register-sure': (this.password || this.password === 0) && (this.againPassword || this.againPassword === 0) }]"
      @click="registerFun"
    >
      注册
    </p>
    <van-popup
      v-model:show="showSuccess"
      position="center"
      :style="{ width: '100%' }"
      :close-on-click-overlay="false"
      class="set-password-popup"
    >
      <div class="close-img" @click="closeFun">
        <img src="@/assets/img/loginRegister/close.png" />
      </div>
      <div class="top">
        <div class="flex flex-center align-center top-title">
          <span></span>注册成功<span></span>
        </div>
        <p class="fast">快快登录进入“灵龙惠生活”，开启快乐逛的体验</p>
        <img src="@/assets/img/loginRegister/link.png" class="left" />
        <img src="@/assets/img/loginRegister/link.png" class="right" />
      </div>
      <div class="bottom" :style="backgroundDiv">
        <div class="welcome">
          <p class="flex flex-center align-center">欢迎关注公众号“灵龙惠生活”</p>
          <p class="flex flex-center align-center">更多优惠信息第一时间通知你</p>
        </div>
        <div class="qr-code">
          <img src="@/assets/img/loginRegister/qrcode.jpg" />
        </div>
        <p class="qr-bottom">也可截图，用微信扫二维码关注</p>
      </div>
    </van-popup>
    <toast-box v-if="showToast" :show-toast="showToast" :msg="msg" @sure="handleSure"></toast-box>
  </div>
</template>
<script>
import ToastBox from "../../components/toast.vue";
import {
    register
} from '@/utils/loginRegister.js';
export default {
  name: "SetPassword",
  components: { 
    ToastBox
  },
  data() {
    return {
      showToast: false,
      twoShow: false,
      oneShow: false,
      password: "",
      againPassword: "",
      showSuccess: false,
      backgroundDiv: {
        backgroundImage:
          "url(" + require("../../assets/img/loginRegister/line.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
    };
  },
  methods: {
    handleSure(val) {
      this.showToast = val;
    },
    eyeFun(name, val) {
      this[name + "Show"] = val;
    },
    registerFun() {
      if (!this.password && this.password !== 0) {
        return
      } else if (!/^[0-9A-Za-z]{8,16}$/.test(this.password) || /^[0-9]{8,16}$/.test(this.password)) {
        this.msg = "密码设置的格式不正确<br>请输入8-16位数字、英文字母的<br>登录密码";
        this.showToast = true;
        return
      }
      if (!this.againPassword && this.againPassword !== 0) {
        return
      } else if (String(this.password) !== String(this.againPassword)) {
        this.msg = "两次密码设置不一致<br>请重新输入密码";
        this.showToast = true;
        return
      }
      let info = sessionStorage.getItem("registerInfo")
      info = info ? JSON.parse(info) : ""
      if (!info) {
        return this.$toast("请从正确方式进入该页面")
      }
      let data = {
          memberId: this.$cookies.get('memberId'),
          username: info.phone,
          password: this.password,
          code: info.smsCode,
          invitationCode: info.inviteCode
      };
      register(data).then(res => {
        if (res && res.errorCode == "0000") {
          this.showSuccess = true;
        }
      })
    },
    closeFun() {
      this.showSuccess = false;
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss" scoped>
.set-password {
  height: 100vh;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
  .title {
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 33px;
    margin: 30px 0 35px 30px;
    text-align: left;
  }
  .box {
    width: 315px;
    margin: 0 auto;
    border-bottom: solid 1px #f5f5f5;
    text-align: left;
    .box-input {
      height: 22px;
      padding: 25px 0 15px 0;
      input {
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
      }
      input::-webkit-input-placeholder {
        color: #cccccc;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .tip {
    width: 315px;
    height: 16px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
    margin: 10px auto 54px;
    text-align: left;
  }
  .register {
    width: 315px;
    height: 44px;
    background: #e62129;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    -ms-border-radius: 22px;
    -o-border-radius: 22px;
    border-radius: 22px;
    opacity: 0.5;
    margin: 0 auto;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 44px;
    text-align: center;
  }
  .register-sure {
    opacity: 1;
  }
}
</style>
<style lang="scss">
.set-password-popup {
  width: 280px !important;
  top: 52px !important;
  transform: translateX(-50%) !important;
  background: none !important;
  .close-img {
    text-align: right;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .top {
    width: 100%;
    height: 98px;
    background: #ffffff;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    margin-top: 10px;
    position: relative;
    &-title {
      width: 100%;
      height: auto;
      padding: 15px 0;
      color: #333;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      span {
        width: 10px;
        height: 1px;
        background-color: #e62129;
      }
      span:nth-child(1) {
        margin-right: 10px;
      }
      span:nth-child(2) {
        margin-left: 10px;
      }
    }
    .fast {
      height: 16px;
      font-size: 11px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
      text-align: center;
    }
    .left,
    .right {
      position: absolute;
      width: 14px;
      height: 48px;
    }
    .left {
      top: 79px;
      left: 20px;
    }
    .right {
      top: 79px;
      right: 20px;
    }
  }
  .bottom {
    width: 100%;
    height: 350px;
    margin-top: 10px;
    padding-top: 15px;
    .welcome {
      height: 36px;
      margin: 0 0 40px;
      p {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        text-align: center;
        line-height: 17px;
      }
    }
    .qr-code {
      width: 220px;
      height: 220px;
      margin: 0 auto;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        transform: scale(1.15);
      }
    }
    .qr-bottom {
      height: 14px;
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 14px;
      margin-top: 10px;
      text-align: center;
    }
  }
}
</style>