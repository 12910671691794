import axios from "./request"

// 注册
export const register = (data) => {
    return axios({
        url: "/api/member/insertMemberUser",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 修改密码
export const updatePassword = (data) => {
    return axios({
        url: "/api/member/updatePassword",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 找回密码
export const findPassword = (data) => {
    return axios({
        url: "/api/member/findPassword",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 登录
export const loginIn = (data) => {
    return axios({
        url: "/api/member/loginIn",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            requestReturn: true
        }
    })
}
// 根据会员编号查询会员信息
export const getmemberinfo = (data) => {
    return axios({
        url: "/api/member/getmemberinfo",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}
// 获取短信验证码
export const getverifycode = (data) => {
    return axios({
        url: "/api/member/getverifycode",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}

// 修改密码
export const updateMemberName = (data) => {
    return axios({
        url: "/api/member/updateMemberName",
        method: "post",
        data,
        config: {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }
    })
}