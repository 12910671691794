<template>
  <div class="toast">
    <van-popup v-model:show="show" position="center" :style="{ width: '80%'}" :close-on-click-overlay="false" round>
      <div class="flex flex-center align-center toast-title">
        <span></span>提示<span></span>
      </div>
      <div class="toast-msg" v-html="msg"></div>
      <van-button type="default" size="large" class="toast-btn" @click="msgConfirmFunc">
        {{ confirmText ? confirmText : '确认' }}
      </van-button>
    </van-popup>
  </div>
</template>
<script>
  export default {
    props: {
      showToast: {
        type: Boolean,
        default: false
      },
      msg: {
        type: String,
        default: ''
      },
      confirmText: {
        type: String,
        default: ''
      }
    },
    computed: {
      show: {
        get() {
          return this.showToast
        },
        set(newVal) {
          this.$emit('sure', newVal)
        }
      }
    },
    data() {
      return {}
    },
    created() {

    },
    methods: {
      msgConfirmFunc() {
        this.show = false;
      }
    }
  }
</script>
<style lang="scss" scoped>
.toast{
  &-title{
    width: 100%;
    height: auto;
    padding: 20px 0;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    span{
      width: 10px;
      height: 1px;
      background-color: #E62129;
    }
    span:nth-child(1){
      margin-right: 8px;
    }
    span:nth-child(2){
      margin-left: 8px;
    }
  }
  &-msg{
    width: auto;
    height: auto;
    padding: 0 20px 20px;
    color: #333;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    line-height: 1.5;
    border-bottom: 1px solid #F0F0F0;
  }
}
</style>
<style>
  .toast-btn .van-button__text{
    font-size: 15px !important;
    color: #E62129 !important;
    font-weight: 500;
  }
  .toast-btn{
    border-width: 0px !important;
  }
</style>